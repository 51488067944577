<template>
  <main>
    <div class="container">
      <div class="main-container">
        <img src="../assets/auth-logo.png" alt="Запчасти для иностранных грузовиков и прицепов" class="main-container__logo">
        <div class="main-container__content">
          <div class="row">
            <div class="col-lg-9 col-md-10">
              <h1>Уважаемые клиенты!</h1>
            </div>
          </div>

          <h3><b>Мы рады приветствовать вас на сайте</b> Системы подарков и вознаграждений «БалтКам»!</h3>
          <p><b>Содержание Акции:</b></p>
          <p>
            При достижении планового объёма закупок (за установленный период проведения Акции),
            Вы получаете возможность выбрать Электронный Подарочный Сертификат на сумму
            в размере <b>10% от суммы прироста к плану</b>. Сумму-план и период проведения акции,
            узнавайте у Вашего менеджера. Сертификаты вручаются при условии отсутствия ПДЗ в отчётном периоде.
          </p>
          <p>Минимальная сумма прироста для участия Акции равна <b>50 000 рублей</b>.</p>
          <p><b>Условия получения Электронного Подарочного Сертификата</b> из <b>Каталога вознаграждений</b>:</p>
          <p>
            В нашем Каталоге вознаграждений вы можете по собственному усмотрению выбрать
            Электронный Подарочный Сертификат (ЭПС). Получение ЭПС возможно в любое удобное вам время
            при положительном балансе бонусных баллов. Выбранный вами ЭПС будет мгновенно доставлен на указанный e-mail.
            Вы можете выбирать любое количество сертификатов в день без ограничений.
          </p>

          <hr>

          <h3>Возникли вопросы?</h3>
          <p>Свяжитесь с нами в рабочие дни <b>с 08:00 до 20:00 (МСК)</b></p>
          <p>Служба заботы о клиентах: <b><a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a></b><br>
            Email: <b><a href="mailto:support@giftery.ru">support@giftery.ru</a></b></p>
          <br>
          <router-link class="button button--large button--rounded" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
          <br>
          <br>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 32.3%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 32.3%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }

  .text-danger {
    color: #c00;
    font-weight: bold;
  }
</style>
